import React, { Component } from "react"
import { navigate } from "gatsby"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import * as ROLES from "../../constants/roles"

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null,
}

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use"

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const {
      firstName,
      lastName,
      email,
      organisation,
      passwordOne,
      isAdmin,
    } = this.state
    const roles = {}

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          firstName,
          lastName,
          email,
          roles,
          organisation,
        })
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.QUESTIONNAIRE)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        console.log(error)

        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      organisation,
      passwordOne,
      passwordTwo,
      //isAdmin,
      error,
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      organisation === "" ||
      firstName === "" ||
      lastName === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-field">
          <label htmlFor="firstName">First name*</label>
          <input
            name="firstName"
            value={firstName}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="input-field">
          <label htmlFor="lastName">Last name*</label>
          <input
            name="lastName"
            value={lastName}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="input-field">
          <label htmlFor="email">Email address*</label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="input-field">
          <label htmlFor="organisation">Organisation*</label>
          <input
            name="organisation"
            value={organisation}
            onChange={this.onChange}
            type="text"
          />
        </div>
        <div className="input-field">
          <label htmlFor="passwordOne">Password*</label>
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
          />
        </div>
        <div className="input-field">
          <label htmlFor="passwordTwo">Confirm Password*</label>
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
          />
        </div>
        {/*
        <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label>
        */}
        <div className="input-field">
          <button className="btn" disabled={isInvalid} type="submit">
            <span>Sign Up</span>
          </button>
        </div>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

export default withFirebase(SignUpFormBase)
