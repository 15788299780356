import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SignUpForm from "../components/SignUp"
import * as ROUTES from "../constants/routes"

export default () => (
  <Layout activePage="signin">
    <section className="page_login">
      <div className="wrapper">
        <div className="row centered">
          <div className="col col-4">
            <h1 className="heading--l thin">Sign Up</h1>
            <SignUpForm />
            <p className="body--s login-link">
              Have an account already? <Link to={ROUTES.SIGN_IN}>Login</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
